/**
 * Created by mankoom on 09.12.2015.
 */
(function( $ ){
    var screenSm = 1182;  // breakpoint from small screen for tablet
    var screenXs = 751;  // breakpoint from small screen for mobile
    var $context = $(document);
    var handlers = {};


    // Functions

           // Position of bootstrap modal window in the center of the screen
    var repositionModal = function () {
        var modal = $(this),
            dialog = modal.find('.modal-dialog');
            modal.css('display', 'block');

        // Dividing by two centers the modal exactly, but dividing by three or four works better for larger screens.

        if ($(window).width() < screenXs) {
            dialog.css("margin-top", Math.max(35, ($(window).height() - dialog.height()) / 2));
        } else {
            dialog.css("margin-top", Math.max(0, ($(window).height() - dialog.height()) / 2));
        }

    };


        // Position of submenu dropdowns
    var repositionDropDownSubmenu = function (button, dropdown) {
        var dropDownTop = button.offset().top -  $(window).scrollTop()  + button.parent().outerHeight();
        var dropDownTopFixed = button.parent().outerHeight();
        var $submenu = $(".js-submenu");
        dropdown.css('top', dropDownTop + "px");
        dropdown.css({'left': button.offset().left + "px" });
        if (dropdown.hasClass('wide')){
            if ($(window).width() < screenSm) {
                dropdown.css({'left': button.offset().left / 4 + "px" })
             }
             if ($(window).width() < screenXs) {
                 dropdown.css({'left': button.offset().left + "px" })
             }
        }
        if ($submenu.hasClass('stick')) {
            dropdown.css('top', dropDownTopFixed + "px");
        }
    };

        // Close navbar when smth in header is clicked
    var closeNavbar = function () {
        if ($(".js-navbar-collapse").hasClass('in')) {
            $(".js-navbar-collapse").removeClass('in');
            $(".js-navbar-toggle").removeClass('expanded');
        }
    };



     /********* HEADER AND SUBMENU HANDLERS *********/

        // Shrink and hide/show header and submenu on scroll down/up
     handlers.scrollPage = function() {
         var lastScrollTop = 0,
             delta = 5;
         $(window).scroll(function(event){
             var st = $(this).scrollTop(),
                 $navbar = $('.js-navbar'),
                 $submenu = $('.submenu'),
                 $header = $('.header'),
                 $navbarHeight = $navbar.outerHeight(),
                 $submenuHeight = $submenu.outerHeight(),
                 $scrollHeight = $navbarHeight + $submenuHeight;
             if(Math.abs(lastScrollTop - st) <= delta)
                 return;

             if (st > lastScrollTop && st < $navbarHeight) {
             } else if (st > lastScrollTop && st > $scrollHeight) {
                 $navbar.addClass('shrink removeActive');

                 $header.addClass('navbar-up');

             } else if (st < lastScrollTop && st > $scrollHeight){
                 // upscroll code
                 $navbar.removeClass('removeActive');
                 $header.removeClass('navbar-up');
             } else if (st < lastScrollTop && st < $scrollHeight) {
                 $navbar.removeClass('shrink');
             }
             lastScrollTop = st;
         });
         $( "form" ).submit(function() {
             $('.header').removeClass('navbar-up');
         });
     };


        // Navbar expand and rotate toggler
    handlers.expandNavbarHandler = function() {
        $context.on("click", ".js-navbar-toggle", function () {
            $(this).toggleClass("expanded");
        });
    };


        // Open search input
    handlers.searchOpenHandler = function() {
        $(".js-search-btn").on('click', function (e) {
            e.preventDefault();
            $(".js-navbar").addClass("searchOpen");
            $(".js-ter-search-input").focus();
            closeNavbar();
        });
    };


        // Close search input
    handlers.searchCloseHandler = function() {
        $context.on("click", ".js-search-clean-btn", function () {
            $(".js-navbar").removeClass("searchOpen")
        });
        // close input with ESC
        $(document).on('keyup',function(evt) {
            if (evt.keyCode == 27) {
                $(".js-navbar").removeClass("searchOpen")
            }
        });
    };

        // Close navbar when logged block is clicked
    handlers.hideNavbarHandler = function() {
        //$context.on("click", ".js-ter-logged-block", function () {
        $('.js-ter-logged-block').on('click', function() {
            closeNavbar();
        });
    };


        // Close navbar when logged block is clicked
    handlers.submenuDropdownHandler = function() {
        $context.on("click mouseover", ".js-dropdown-toggle", function () {
            var button = $(this);
            var dropdown = button.next('.js-dropdown-menu');
            repositionDropDownSubmenu(button, dropdown);
        });
    };


        // hide dropdowns in submenu and main and user logged block on and scroll
    handlers.hideWhenScrollHandler = function() {
        $(window).on('scroll', function() {
            if ((window.scrollY) > 1) {
                $('.js-submenu-dropdown-wrapper').removeClass('open');
                $('.js-ter-logged-block').removeClass('open');
            }
        });
        $('.js-submenu, .js-inbox-tabs').on('scroll', function() {
            $('.js-submenu-dropdown-wrapper').removeClass('open');
        });
    };


        // Arrow tip for submenu with overflow
    handlers.submenuArrowTipHandler = function() {
        $(window).on("load resize", function () {
            var width = 0;
            $('.js-submenu-item').each(function() {
                width += $(this).parent().outerWidth( true );
            });
            var windowWidth = $(window).width();
            if (width > windowWidth ) {
                $('.js-scroll-hint-next').fadeIn('fast');
            } else {
                $('.js-scroll-hint-next').fadeOut('fast');
            }


        });
        $(".js-submenu").on('scroll', function() {
            if ($(this).scrollLeft() > 50) {
                $('.js-scroll-hint-prev').fadeIn('fast')
            } else {
                $('.js-scroll-hint-prev').fadeOut('fast')
            }
        })

    };


        // Arrow indicators for dropdowns with long list of items on mobile
    handlers.submenuLongDropdownHandler = function() {
        // get the height of .child
        var scrollval = $('.js-dropdown-menu-item').height(); //35
        // work out the total height.
        $('.js-dropdown-menu-content').on("scroll",  function () {
            var content = $(this);
            var currentscrollval = content.scrollTop();
            var totalheight = content.height();
            if (currentscrollval > scrollval) {
                $(".js-list-up").removeClass('dim');

            } else {
                $(".js-list-up").addClass('dim');
            }
            if ((currentscrollval + totalheight) >= $(this)[0].scrollHeight ) {
                $(".js-list-down").addClass('dim');
            } else {
                $(".js-list-down").removeClass('dim');
            }
        });
    };

        // Boostrap tooltip initialization
    handlers.tooltipInit = function() {
        $('[data-toggle="tooltip"]').tooltip()
    }


    /********* CONTROL HANDLERS *********/

        // Textarea - Counter of remaining elements
    handlers.textareaCounterHandler = function() {
        $context.on("propertychange keyup input paste", ".js-textarea", function () {
            var limit = $(this).data("limit");
            var remainingChars = limit - $(this).val().length;
            if (remainingChars <= 0) {
                $(this).val($(this).val().substring(0, limit));
                remainingChars = 0;
            }
            $(this).parent().find('.js-textarea-feedback').html(remainingChars + ' left');
        });
    };


        // Custom scroll - add and focus on chosen element

    handlers.scrollHandler = function() {
        $(".js-news-block-content, " +
            ".js-submenu-dropdown-wrapper .dropdown-menu, " +
            ".dropdown-button-wrapper  .dropdown-menu, " +
            ".js-single-block-page-content")
            .mCustomScrollbar({
            axis:"y",
            theme:"dark",

            mouseWheel:{
                preventDefault: true,
                scrollAmount: 100
            }
        });


        // return native scroll for tablet/mobile devices
  
        $(".selectpicker").selectpicker({
            tickIcon: 'icon-ok',
            selectedTextFormat: "count > 3"
        })
        $(document).on('focusout', '.dropdown-menu.inner', function (e) {
            var $list = $(this);
            setTimeout(function () {
                if (!$.contains($list[0], document.activeElement)) {
                    var $container = $list.closest('.bootstrap-select').not('.show-tick').removeClass("open");
                }
            }, 100);
        });
    };


    handlers.scrollFormHandler = function() {
        $(".js-tab-pane-form").mCustomScrollbar({
            axis:"y",
            theme:"dark"
        });
    }


    /********* GENERAL HANDLERS *********/

        // Modal window
    handlers.modalWindowHandler = function() {

        // Position of modal  in the center of the screen
        $('.modal').on('show.bs.modal', repositionModal);
        $(window).on('resize orientationchange', function() {
            $('.modal:visible').each(repositionModal);
        });


        // Remove scroll when one modal triggers another
        $('.js-modal-second').on('show.bs.modal', function() {
            $('body').addClass('body-overflow');
        });
        $('.js-modal-second').on('hide.bs.modal', function() {
            $('body').removeClass('body-overflow');
        });


    };

        // Retina
    handlers.retinaImages = function() {
        $(window).on("load ", function () {
            retinajs();
        });
    };

        // Fix for browsers which do not support calc
    handlers.calcHandler = function() {
        if (Modernizr.csscalc) {
            return false;
        } else {
            $('.form-control').addClass('no-calc-width');
            $('.top-city').addClass('no-calc-width');
            $('.js-search-form').addClass('no-calc-width');
            $('.js-submenu').addClass('no-wrap');
            $('.js-inbox-tabs').addClass('no-wrap');
            $('.js-scroll-hint-next').addClass('hidden');
            $(window).on('load resize orientationchange', function() {
                $('.js-middle-container').each(function(){
                    if ($(window).width() > screenSm) {
                        $(this).css('width', $(this).parent().width()-170);
                    } else {
                        $(this).css('width', 'auto');
                    }
                });
                $('.profile-main-container-right').each(function(){
                    if ($(window).width() > screenSm) {
                        $(this).css('width', $(this).parent().width()-226);
                    } else {
                        $(this).css('width', 'auto');
                    }
                })
            });
        }
    };

        // Amimated slide in form with tabs
    handlers.slideTab = function() {
        $('.nav-tabs-wrapper li.active').each(function () {
            var $this = $(this),
                offset = $this.position().left,
                width = $this.width() + 5;
            var $wrapper = $this.closest('.nav-tabs-wrapper');
            var $slider = $wrapper.find('.slider');
            $slider.css({ left: offset, width: width });
        });
        $('.nav-tabs-wrapper li').on('click', function() {
            var $this = $(this),
                offset = $this.position().left,
                width = $this.width();
            var $wrapper = $this.closest('.nav-tabs-wrapper');
            var $slider = $wrapper.find('.slider');
            $slider.css({ left: offset, width: width });
        });
    };


    /********* CUSTOM HANDLERS *********/

        // Registration page and submit review page - show tip
    handlers.tipHandler = function() {
        if ($(window).width() < screenSm) {
            $context.on("click", ".js-form-group-tip", function (e) {
                e.stopPropagation();
                var $this = $(this);
                var tipId = $this.data("cardname");
                $("#" + tipId).toggleClass("hidden");
            });
            $(window).on('click touchmove', function(){
                $(".js-form-group-tip-text").addClass("hidden");
            });
        } else {
            $context.on("mouseover mouseout", ".js-form-group-tip", function () {
                var $this = $(this);
                var tipId = $this.data("cardname");
                $("#" + tipId).toggleClass("hidden");
            });
        }
    };


        // Login page - show forget password block
    handlers.forgetPasswordHandler = function() {
        $context.on("click", ".js-forget-password-link", function () {
            $(".js-login").toggleClass("hidden");
            $(".js-forget-password").toggleClass("hidden");
        });
    };


         // My Ter page
            // show and hide news text
    handlers.toggleNewsHandler = function() {
        $context.on("click", ".js-news-link-show, .js-news-link-hide", function () {
            $(".js-news-paragraph").toggleClass("hidden-xs").toggleClass("hidden-sm");
            $(".js-news-link-show").toggleClass("hidden");
            $(".js-news-link-hide").toggleClass("hidden");
        });
    };

            // magnific popup
    handlers.galleryHandler = function() {
        $('.js-custom-scroll-horizontal').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: '.js-thumbnail', // the selector for gallery item
                type: 'image',
                gallery: {
                    enabled:true,
                    navigateByImgClick: true,
                    arrowMarkup: '<button title="%title%" type="button" class="arrow-%dir%"></button>', // markup of an arrow button
                    tPrev: 'Previous (Left arrow key)', // title for left button
                    tNext: 'Next (Right arrow key)', // title for right button
                    tCounter: '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
                }
            });
        });
    };



        // My Ter Buy Vip page -  tab switch
    handlers.tabSwitchHandler = function() {
        $context.on('click', '.js-tab', function(){
            var $this = $(this),
                data = $this.data() || {},
                target = data.control || false;
            $("[data-depend]").addClass("hidden-xs")
                .filter("[data-depend='"+target+"']")
                .removeClass("hidden-xs");
            $("[data-control]").removeClass("active")
                .filter("[data-control='"+target+"']")
                .addClass("active");
        });
    };


        // Discussion board page -  view switch
    handlers.boardViewSwitchHandler = function() {
        $context.on("click", ".js-collapse-view", function () {
            $(".js-media-inserted").addClass("hidden");
            $(".js-uncollapse-view").removeClass("hidden");
            $(".js-collapse-view").addClass("hidden");
        });
        $context.on("click", ".js-uncollapse-view", function () {
            $(".js-media-inserted").removeClass("hidden");
            $(".js-uncollapse-view").addClass("hidden");
            $(".js-collapse-view").removeClass("hidden");
        });
    };


        // Search Reviews results page - table cell place change on mobile
    handlers.cellChangePlaceHandler = function() {
        if ($(window).width() < screenXs) {
            $('.js-ter-table .tr').each(function() {
                var tr = $(this);
                var td1 = tr.find('.td:eq(4)'); // indices are zero-based here
                var td2 = tr.find('.td:eq(5)');
                td1.detach().insertAfter(td2);
            });
        }
    };

    // Search Reviews show transexual extra fields

    handlers.showExtraFields = function() {
        $context.on("change", '.js-showExtraFields', function(){
            if ($( ".js-showExtraFields option:selected").val() == 2 ) {
                $('.form-group-more').removeClass("hidden");
            } else {
                $('.form-group-more').addClass("hidden");
            }
        });
    };


    handlers.textareaAutoresize = function() {
        autosize($('textarea'));
    };


    handlers.toggleMessageHandler = function() {
        $context.on("click", ".js-toggle-message", function () {
            $(".js-message-text").toggleClass("hidden");
        });
    };

    handlers.contentEditableFixHandler = function() {
        $(document).chromeinsertfix();
        $(".js-form-control-editable-area").chromeinsertfix();
    };

    handlers.locationAutocompltet = function() {
        var $autocomplete = $(".js-autocomplete"),
            $clear = $(".js-input-clear");

        $autocomplete.on('click focus', function() {
            var $this = $(this);
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
                if ($this.val().length != 0) {
                    $this.parent().find($clear).addClass('active');
                }
            } else {
                $this.select();
            }

        });

        $autocomplete.on('keyup paste cut', function() {
            var $this = $(this);
            var value = $this.val();
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
                if (value && value.length > 0) {
                    $this.parent().find($clear).addClass('active');
                } else {
                    $this.parent().find($clear).removeClass('active');
                }
            }

        });

        $clear.on('click', function() {
            var $this = $(this);
            var autocomplete = $this.parent().find($autocomplete);
            autocomplete.val('').focus();
            autocomplete.typeahead('updater', '');
            $this.removeClass('active');
        });
    };


    handlers.backToTop = function() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
            $(window).on('scroll', function() {
                var offset = 100;
                if ($(this).scrollTop() > offset) {
                    $(".js-back-top").fadeIn('fast');
                } else {
                    $(".js-back-top").fadeOut('fast');
                }
            });
            $(document).on('click', '.js-back-top', function() {
                event.preventDefault();
                $('html, body').animate({scrollTop: 0});
                return false;
            })
        }

    }


   handlers.starRating = function() {
       $(".js-rating").each( function() {
           var rating = $(this).attr("data-rating");
           $(this).rateYo({
               rating: rating,
               totalStars: 5,
               starWidth: "16px",
               ratedFill: "#ffce37",
               normalFill: "#dbe0e4",
               readOnly: true,
               precision: 2
           });
        });
       $(".js-rating-basic").each( function() {
           var rating = $(this).attr("data-rating");
           $(this).rateYo({
               rating: rating,
               totalStars: 5,
               starWidth: "16px",
               ratedFill: "#dbe0e4",
               normalFill: "#dbe0e4",
               readOnly: true,
               precision: 2
           });
       });
    };


    var bindCheckboxContol = function(){
        var $table = $(this),
            $checkAll = $('.js-check-all', $table),
            $checkbox = $('input[type="checkbox"]',$table).filter(':not(.js-check-all)');

        $checkbox.on('change', function(){
            var $this = $(this);
            $this.closest(".tr").toggleClass("active");
            if (!$this.prop("checked")){
                $checkAll.removeProp("checked");
            }
        });

        $checkAll.on('change', function(){
            var $this = $(this);
            $checkbox.prop("checked", $this.prop("checked")).closest(".tr").toggleClass("active");
            if ($('input:checkbox:checked').length != ($checkbox.length)) {
                $checkbox.closest(".tr").addClass("active");
             }
            if ($('input:checkbox:checked').length == 0) {
                $checkbox.closest(".tr").removeClass("active");
            }
        })
    };




    handlers.checkAllCheckbox = function() {
        $("[data-control='checkbox']").each(bindCheckboxContol);
    };



    var runHandler = function(func){
        if (typeof func === "function"){
            func();
        }
    };

    var bindEvents = function(){
        $.map(handlers, runHandler);
    };


    $(bindEvents);

    $(document).on("click", ".call-alert-success", function(e) {
        repositionModal();
        bootbox.dialog({
            className: 'modal-alert success',
            title: "<i class='icon-success-new'></i>",
            message:  '<h5 class="modal-alert-subtitle">Success!</h5>' +
            '<p class="modal-alert-text">Your review was successfully added</p>',
            onEscape: true,
            backdrop: true
        });
    });
    $(document).on("click", ".call-alert-error", function(e) {
        repositionModal();
        bootbox.dialog({
            className: ' modal-alert error',
            title: "<i class='icon-error'></i>",
            message: '<h5 class="modal-alert-subtitle">Oops!..</h5>' +
            '<p class="modal-alert-text">Sorry, your review was not added</p>',
            onEscape: true,
            backdrop: true
        });
    });
    $(document).on("click", ".call-alert-warning", function(e) {
        repositionModal();
        bootbox.dialog({
            className: ' modal-alert warning',
            title: "<i class='icon-warning'></i>",
            message: '<h5 class="modal-alert-subtitle">Warning!</h5>' +
            '<p class="modal-alert-text">It looks like you already started writing a review for a different provider. You would need to finish that review first or delete the draft prior to reviewing another provider.</p><button class="btn btn-primary" data-dismiss="modal">Finish Review</button>',
            onEscape: true,
            backdrop: true
        });
});
    $(document).on("click", ".call-confirm", function(e) {
        repositionModal();
        bootbox.confirm({
            message: "Are you sure",
            callback: function (result) {

            },
            onEscape: true,
            backdrop: true
        });
    });


    //TODO: move to separate file
    $.ter = $.ter || {};
    $.ter.DialogManager = function() {
        this.showSuccess = function(text, callback) {
            repositionModal();
            bootbox.dialog({
                className: 'modal-alert success',
                title: "<button type='button' class='sr-only close-x' data-dismiss='modal'>Close</button><i class='icon-success-new'></i>",
                message:  '<h5 class="modal-alert-subtitle">Success!</h5>' +
                '<p class="modal-alert-text">' + text + '</p>',
                onEscape: function () {
                    if (callback && typeof callback == 'function') {
                        callback();
                    }
                    return true;
                },
                backdrop: true
            });
        };

        this.showError = function(text, callback) {
            repositionModal();
            bootbox.dialog({
                className: ' modal-alert error',
                title: "<button type='button' class='sr-only close-x' data-dismiss='modal'>Close</button><i class='icon-error'></i>",
                message: '<h5 class="modal-alert-subtitle">Oops!..</h5>' +
                '<p class="modal-alert-text">' + text + '</p>',
                onEscape: function () {
                    if (callback && typeof callback == 'function') {
                        callback();
                    }
                    return true;
                },
                backdrop: true
            });
        };

        this.showWarning = function(text, callback) {
            repositionModal();
            bootbox.dialog({
                className: ' modal-alert warning',
                title: "<button type='button' class='sr-only close-x' data-dismiss='modal'>Close</button><i class='icon-warning'></i>",
                message: '<h5 class="modal-alert-subtitle">Warning!</h5>' +
                '<p class="modal-alert-text">' + text + '</p>',
                onEscape: function () {
                    if (callback && typeof callback == 'function') {
                        callback();
                    }
                    return true;
                },
                backdrop: true
            });
        };

        this.showConfirm = function(text, okCallback) {
            bootbox.confirm({
                message: text,
                callback: function (result) {
                    if (result === true && typeof(okCallback) === "function") {
                        okCallback();
                    }
                },
                onEscape: true,
                backdrop: true
            });
            repositionModal();
        }

    }

    $.extend($.ter,
        {
            DialogManager: new $.ter.DialogManager()
        }
    );

    //TODO: move to separate file
    $.ter = $.ter || {};
    $.ter.TimezoneUtils = function () {
        CLIENT_TZ_OFFSET_COOKIE_KEY = "TerTzOffset";
        CLIENT_TZ_OFFSET_COOKIE_TTL = 365;

        function createCookie(name, value, days) {
            var expires;

            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toGMTString();
            } else {
                expires = "";
            }
            document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
        }

        function readCookie(name) {
            var nameEQ = encodeURIComponent(name) + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
            return null;
        };

        function eraseCookie(name) {
            createCookie(name, "", -1);
        };

        this.setTzOffsetCookieIfAbsent = function () {
            var tzOffsetCookie = readCookie(CLIENT_TZ_OFFSET_COOKIE_KEY);
            if (tzOffsetCookie != null) {
                return;
            }

            var d = new Date();
            var tzOffset = -d.getTimezoneOffset();

            createCookie(CLIENT_TZ_OFFSET_COOKIE_KEY, tzOffset, CLIENT_TZ_OFFSET_COOKIE_TTL);
        }
    }

    $.extend($.ter,
        {
            TimezoneUtils: new $.ter.TimezoneUtils()
        }
    );
})(jQuery);